import React, {useRef} from "react";
import css from './offerPage.module.scss';
import {graphql, Link, useStaticQuery} from 'gatsby';
import CompanyContactMail from "../CompanyContactMail/CompanyContactMail";
import {getLink, getRoutes} from "../routes";
import SEO from "../SEO";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Footer from "../Footer/Footer";
import Layout from "../Layout";
import MainButton, {mainButtonArrow, mainButtonFill} from "../MainButton/MainButton";

const OfferPage = props => {
    const {
        image,
        imageAlt,
        name = '',
        whatYouCanExpectFromUs = '',
        scopeOfDuties = '',
        ourRequirements = ''
    } = props;

    const {site} = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                descriptionForCareersPages
              }
            }
          }
        `
    );

    const routes = getRoutes().Careers;
    const pageTitle = routes.pageTitle;
    const sectionAnchor = routes.anchor

    const whatYouCanExpectFromUsHeader = 'What you can expect from us';
    const whatYouCanExpectFromUsText = whatYouCanExpectFromUs || <><p>working in a team inspired and fascinated by
        Computer Vision technologies</p><p>friendly atmosphere, daily cooperation with open minded people</p><p>various,
        interesting projects in cutting-edge technologies</p><p>real personal impact on implemented projects</p>
        <p>renumeration package adequate to your experience</p><p>hybrid/flexible working hours</p></>;
    const scopeOfDutiesHeader = "Scope of duties";
    const ourRequirementsHeader = "Our requirements";

    const renderColumn = (index, title, text) => {
        return (
            <div className={css.column} data-index={index}>
                <div className={css.columnTitle}>{title}:</div>
                <div className={css.columnText}>{text} </div>

            </div>
        );
    };

    const renderLogoBox = () => {
        return (
            <div className={css.offerNameWrapper}>
                <div className={css.imageWrapper}>
                    {image && <img src={image} alt={imageAlt}/>}
                </div>
                <div className={css.nameWrapper}>
                    {name}
                </div>
            </div>
        );
    };

    const renderOneColumnView = () => {
        return (
            <div className={[css.oneColumnView, css.boxesWrapper].join(' ')}>
                {renderLogoBox()}
                {renderColumn(1, whatYouCanExpectFromUsHeader, whatYouCanExpectFromUsText)}
                {renderColumn(2, scopeOfDutiesHeader, scopeOfDuties)}
                {renderColumn(3, ourRequirementsHeader, ourRequirements)}
            </div>
        );
    };

    const renderTwoColumnView = () => {
        return (
            <div className={[css.twoColumnView, css.boxesWrapper].join(' ')}>
                {renderLogoBox()}
                {renderColumn(1, whatYouCanExpectFromUsHeader, whatYouCanExpectFromUsText)}
                {renderColumn(2, scopeOfDutiesHeader, scopeOfDuties)}
                {renderColumn(3, ourRequirementsHeader, ourRequirements)}
            </div>
        );
    };

    const renderFourColumnView = () => {
        return (
            <div className={[css.fourColumnView, css.boxesWrapper].join(' ')}>
                {renderLogoBox()}
                <div className={css.columnsWrapper}>
                    {renderColumn(1, whatYouCanExpectFromUsHeader, whatYouCanExpectFromUsText)}
                    {renderColumn(2, scopeOfDutiesHeader, scopeOfDuties)}
                    {renderColumn(3, ourRequirementsHeader, ourRequirements)}
                </div>

            </div>
        );
    };

    const applyButton = (
        <MainButton
            label={"Apply now!"}
            isLink={false}
            arrow={mainButtonArrow.right}
            fill={mainButtonFill.gradient}
        />
    );

    return (
        <Layout>
            <SEO title={pageTitle} description={site.siteMetadata.descriptionForCareersPages}/>

            <Breadcrumbs
                title={name}
                parentTitles={[pageTitle]}
                parentRoutes={[routes]}
                sectionAnchors={[sectionAnchor]}
            />

            <div className={css.wrapper}>
                {renderOneColumnView()}
                {renderTwoColumnView()}
                {renderFourColumnView()}

                <div className={css.buttonsWrapper}>
                    <Link to={`${getLink([routes])}#${sectionAnchor}`}>
                        <MainButton
                            label={`Back to ${routes.pageTitle}`}
                            arrow={mainButtonArrow.left}
                        />
                    </Link>
                    <CompanyContactMail
                        contentToDisplay={applyButton}
                        subject={"Job application"}
                    />
                </div>

                <div className={css.clauseWrapper}>
                    <div className={css.contentBg}/>
                    <div className={css.contentInner}>
                        <div className={css.clauseHeader}>
                            <p>For European Union Citizens only:</p><br/><p>Please add a clause to your CV:</p>
                        </div>
                        <div className={css.clauseText}>
                            "I agree to the processing of personal data provided in this document for realising the
                            recruitment process by Deeplai P.S.A. Al. Kraśnicka 31, 20-718 Lublin, pursuant to the
                            Personal
                            Data Protection Act of 10 May 2018 (Journal of Laws 2018, item 1000) and in agreement with
                            Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on
                            the
                            protection of natural persons with regard to the processing of personal data and on the free
                            movement of such data, and repealing Directive 95/46/EC (General Data Protection
                            Regulation)"
                        </div>
                    </div>
                </div>

                <div className={css.personalDataProcessedByWrapper}>
                    <div className={css.contentBg}/>
                    <div className={css.contentInner}>
                        Your personal data will be processed by Deeplai P.S.A. with its registered office in Lublin, Al.
                        Kraśnicka 31, 20-718 Lublin, only for the purpose of conducting the recruitment process. You can
                        request
                        the removal or change of data at any time by sending an e-mail to{" "}
                        <CompanyContactMail subject={"Personal data management question"}/>.
                    </div>
                </div>

            </div>

            <Footer/>

        </Layout>
    )
};

export default OfferPage;
